import React from "react";
import "./contact.scss";
import Heading from "../heading/heading";
function contact() {
  return (
    <div className="contact" id="contact">
      <Heading name="Contact" />
      <div>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur,
        veritatis iusto reiciendis laboriosam quaerat provident architecto
        expedita, aliquid consectetur in dicta excepturi impedit harum quia,
        earum qui suscipit! Iste, laudantium.
      </div>
    </div>
  );
}

export default contact;
